<template>
  <div v-loading="isLoading" class="referral">
    <div class="referral__content">
      <div class="referral__title">
        Создание реферальной ссылки
      </div>
      <div class="sidebar__content">
        <p class="referral-link__title">Реферальная ссылка</p>
        <div class="referral-form">
          <div class="referral-form-container">
            <input id="link-name" 
              class="referral-form-input" 
              type="text" 
              placeholder="Название ссылки" 
              v-model="linkName"
              :class="{'error-border': !isLinkNameValid}"
            />
          </div>
        </div>
      </div>
      <div class="referral-conditions">
        <div class="referral-percents-title">
          Условия по реферальной ссылке
        </div>
        <div class="referral-percents-inputs">
          <div class="referral-percents-form">
            <label class="referral-percents-label">Для себя, %</label>
            <input type="number" v-model.number="forSelf" @input="updateFromInputs" min="0" max="100" class="referral-percents-input"/>
          </div>
          <div class="referral-percents-form">
            <p class="referral-percents-label">Для реферала, %</p>
            <div class="referral-percents-input">{{ forReferral }}</div>
          </div>
        </div>
        <div class="range-container">
          <input class="custom-range" type="range" v-model="sliderValue" @input="updateFromSlider" min="0" max="100" />
        </div>
        <table class="referral__table-container">
          <thead>
            <tr>
              <th>Платформы</th>
              <th>Текущий грейд</th>
              <th>Процент реферала</th>
              <th>Мой процент</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(grade, platform) in platforms">
              <tr v-if="grade.grade" :key="platform">
                <td class="referral-platform">{{ ServiceName[platform] }}</td>
                <td>{{grade.grade }}</td>
                <td>
                  {{ manuallyEditedPlatforms.includes(platform) 
                    ? (grade.grade - grade.selfPercent).toFixed(2)
                    : calculateReferralPercent(grade.grade) 
                  }}</td>
                <td>
                  <input 
                    class="referral-my_precent" 
                    type="number" 
                    v-model="grade.selfPercent"
                    @input="handleSelfPercentInput(platform, grade.grade)" 
                    min="0" 
                    :max="grade.grade" 
                    step="0.01" 
                  />
                </td>
              </tr>
            </template>
            
          </tbody>
        </table>
      </div>
    </div>
    <div class="referral__footer">
      <div class="referral__footer-container">
        <button class="referral__footer-create" @click="createReferralLink">
          Создать 
        </button>
        <button @click="closeDialog" class="referral__footer-cancel">
          Отмена
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ServiceName } from "@/utils/services"

export default {
  data() {
    return {
      forSelf: 50,
      forReferral: 50,
      sliderValue: 50,
      isManualEdit: false,
      isLinkNameValid: true, 
      linkName: '', 
      platforms: [], 
      isLoading: true, 
      error: null,
      manuallyEditedPlatforms: [],
      ServiceName,
    };
  },
  computed: {
    user() {
      return this.$store.state.Auth.user
    },
  },
  async created() {
    await this.fetchPlatformsData();
  },
  methods: {
    closeDialog() {
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: false })
      setTimeout(() => {
        this.$store.commit('Dialog/setData', { label: 'activeDialog', data: '' })
      }, 200)
    },
    async fetchPlatformsData() {
      const clientToken = localStorage.getItem('client_token');
      this.isLoading = true;
      try {
        const data = { token: clientToken };
        const response = await this.$store.dispatch('Referrals/getServices', data);
        this.platforms = response.data || {}; 
        this.setDefaultSelfPercent();
      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      } finally {
        this.isLoading = false;
      }
    },

    setDefaultSelfPercent() {
      Object.keys(this.platforms).forEach((platformName) => {
        let platform = this.platforms[platformName]; 
        if (typeof platform === 'number') {
          platform = { grade: platform, selfPercent: '0.00' }; 
          this.platforms[platformName] = platform;
        }

        platform.selfPercent = this.calculateReferralPercent(platform.grade);
      });
    },

    calculateReferralPercent(grade) {
      return ((grade * this.forReferral) / 100).toFixed(2);
    },

    calculateSelfPercent(grade) {
      return ((grade * this.forSelf) / 100).toFixed(2);
    },

    updateFromSlider() {
      this.isManualEdit = false;
      this.manuallyEditedPlatforms = []

      this.forReferral = 100 - this.sliderValue;
      this.forSelf = this.sliderValue;
      this.updateAllSelfPercent();
    },
    
    updateFromInputs() { 
      if (this.forSelf < 0) this.forSelf = 0;
      if (this.forReferral < 0) this.forReferral = 0;

      if (this.forSelf > 100) this.forSelf = 100;

      if (this.forSelf + this.forReferral !== 100) {
        this.forReferral = 100 - this.forSelf;
      }

      if (this.forSelf + this.forReferral > 100) {
        const diff = this.forSelf + this.forReferral - 100;
        if (this.forSelf > this.forReferral) {
          this.forSelf -= diff;
        } else {
          this.forReferral -= diff;
        }
      }

      
      this.sliderValue = this.forSelf;
      this.updateAllSelfPercent();
    },

    updateAllSelfPercent() {
      Object.keys(this.platforms).forEach((platformName) => {
        let grade = this.platforms[platformName];

        if (typeof grade === 'object' && grade.grade !== undefined) {
          grade = grade.grade;
        }
        const numericGrade = parseFloat(grade);

        if (!isNaN(numericGrade)) {
          this.platforms[platformName] = {
            grade: numericGrade,
            selfPercent: this.calculateSelfPercent(numericGrade),
          };
        } else {
          console.error(`Invalid grade for ${platformName}:`, grade);
        }
      });
    },

    
    handleSelfPercentInput(platform, grade) {
      this.isManualEdit = true;

      if (!this.manuallyEditedPlatforms.includes(platform)) {
        this.manuallyEditedPlatforms.push(platform);
      }

      let newSelfPercent = parseFloat(this.platforms[platform].selfPercent);

      if (isNaN(newSelfPercent)) {
        newSelfPercent = 0.00;
      }

      // Ensure the newSelfPercent is within the allowed range
      if (newSelfPercent > grade) {
        newSelfPercent = grade;
      } else if (newSelfPercent < 0) {
        newSelfPercent = 0.00;
      }

      this.platforms[platform].selfPercent = newSelfPercent.toFixed(2);
    },

    async createReferralLink() {
      if (!this.linkName.trim()) {
        this.isLinkNameValid = false;
        return; 
      }
      this.isLinkNameValid = true;

      const title = this.linkName;
      const services =   Object.keys(this.platforms).map((platform) => ({
        service: platform,
        percent: this.platforms[platform].selfPercent,
      }));
      
      const data = {
        title,
        services,
      };

      const clientToken = localStorage.getItem('client_token');
      this.isLoading = true 
      try {
        await this.$store.dispatch('Referrals/postAgentLink', {agent: this.user.agent.id, data, token: clientToken})
        this.isLoading = false
        this.closeDialog()
      } catch(e) {
        console.error(e)
        this.isLoading = false
      }
    },
  },
};
</script>

<style scoped lang="scss">
.link-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.link {
  margin-right: 10px;
  text-decoration: none;
  color: blue;
  position: relative;
}

.link:hover::after {
  content: attr(title);
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  top: 20px;
  left: 0;
  white-space: nowrap;
  z-index: 10;
}

.referral {
  font-family: "Roboto", serif;
  background: #fff;
  color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__content {
    padding: 70px 100px 0 80px;
  }

  &-form {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    &-container {
      display: flex;
      column-gap: 20px;
    }

    &-input {
      width: 100%;
      padding: 15px 0 15px 14px;
      border: 1px solid #D1D4D8;
      border-radius: 2px;
      font-size: 16px;
      font-weight: 400;
      line-height: 18.75px;
      color: #9BA2AB;

      &::placeholder {
        font-size: 16px;
        font-weight: 400;
        line-height: 18.75px;
        color: #9BA2AB;
      }
    }
  }

  &__footer {
    background: #F5F7FA;
    padding: 0 0 30px 0;
    padding: 30px 120px 30px 80px;
    margin-top: 40px;

    &-container {
      display: flex;
      align-items: center;
      column-gap: 20px;
    }

    &-create {
      background: #395FE4;
      height: 50px;
      width: 100%;
      border-radius: 4px;
      color: #FFFFFF;
      font-size: 16px;
      font-weight: 700;
      line-height: 18.75px;
      border: 1.5px solid transparent;
    }

    &-cancel {
      width: 100%;
      height: 50px;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 700;
      line-height: 18.75px;
      border: 1.5px solid #2F80ED;
      background-color: transparent;
      color: #395FE4;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 30px;
    line-height: 35.16px;
    margin-bottom: 48px;
    color: #000;
  }

  &-link {
    &__title {
      font-size: 20px;
      font-weight: 700;
      line-height: 23.44px;
      color: #333333;
      margin-bottom: 20px;
    }

    &-container {
      max-width: 282px;
      width: 100%;
      border: 1px solid #D1D4D8;
      border-radius: 2px;
      padding: 15px 14px;
      display: flex;
      column-gap: 6px;
      justify-content: space-between;
    }

    &-link {
      font-size: 16px;
      font-weight: 500;
      line-height: 18.75px;
      color: #333333;
      text-decoration: underline;
      display: inline-block;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &_copy {
      margin: 0;
      padding: 0;
      border: none;
      background: transparent;

      &:hover {
        cursor: pointer;
      }

      svg {
        path {
          fill: #9BA2AB;
        }
      }
    }
  }

  &-platform {
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    color: #9BA2AB;
  }

  &-percents {
    &-title {
      font-size: 20px;
      font-weight: 700;
      line-height: 23.44px;
      color: #333333;
      margin-bottom: 22px;
    }

    &-inputs {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    &-form {
      max-width: 160px;
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 7px;
    }

    &-label {
      padding-left: 14px;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.06px;
      color: #333333;
    }

    &-input {
      border: 1px solid #D1D4D8;
      font-size: 16px;
      font-weight: 400;
      line-height: 18.75px;
      color: #9BA2AB;
      border-radius: 2px;
      padding: 15px 0 15px 12px;
    }
  }
}

.sidebar__content {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 40px;
}

.referral-my_precent {
  border: 1px solid #D1D4D8;
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  width: 10rem;
  padding: 10px 0;
  text-align: center;
  border-radius: 2px;
}

.referral__table-container {
  overflow-y: auto;
  width: 100%;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px
}

thead {
  border-bottom: 1px solid #BFC2C6;
  padding-bottom: 12px;
}

table th,
table td {
  border: none;
  padding: 0;
  padding-top: 20px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  color: #333333;
}

table th {
  padding-bottom: 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  color: #BFC2C6;
}

input[type="range"] {
  width: 100%;
  margin: 1rem 0;
}

input[type="range"].disabled {
  background: #ccc;
  cursor: not-allowed;
}

.range-container {
  position: relative;
  width: 100%;
}

.custom-range {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  background: linear-gradient(to right, #9CAFF1 0%, #395FE4 50%);
  outline: none;
  position: relative;
}

.custom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #395FE4;
  cursor: pointer;
  border: none;
}

.custom-range::-moz-range-thumb {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #395FE4;
  cursor: pointer;
  border: none;
}

.custom-range::-ms-thumb {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #395FE4;
  cursor: pointer;
  border: none;
}

.custom-range::-moz-range-track {
  border-radius: 5px;
}

.custom-range::-ms-track {
  height: 18px;
  border: none;
}

.error-border {
  border: 1px solid red;
}
</style>
